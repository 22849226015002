export var bookList = [
    {
        name: "单音节发声练习",
        imgLink: require("../assets/book/book1.jpg"),
        index: 1,
        units: [
            {
                unit: "Aa",
                pages: [
                    {
                        name: "第四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Ba",
                pages: [{
                    name: "第六页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%85%AD%E9%A1%B5/%E7%AC%AC6%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },]
            },
            {
                unit: "Cc",
                pages: [{
                    name: "第八页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                },
                    {
                        name: "第九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },]
            },
            {
                unit: "Dd",
                pages: [{
                    name: "第十页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                },
                    {
                        name: "第十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },]
            },
            {
                unit: "Ee",
                pages: [
                    {
                        name: "第十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Ff",
                pages: [
                    {
                        name: "第十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Gg",
                pages: [
                    {
                        name: "第十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Hh",
                pages: [
                    {
                        name: "第十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Ii",
                pages: [
                    {
                        name: "第二十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第二十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Jj",
                pages: [
                    {
                        name: "第二十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第二十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Kk",
                pages: [
                    {
                        name: "第二十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第二十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Ll",
                pages: [
                    {
                        name: "第二十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第二十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Mm",
                pages: [
                    {
                        name: "第二十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第二十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Nn",
                pages: [
                    {
                        name: "第三十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第三十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Oo",
                pages: [
                    {
                        name: "第三十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第三十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Pp",
                pages: [
                    {
                        name: "第三十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第三十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Qq",
                pages: [
                    {
                        name: "第三十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第三十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Rr",
                pages: [
                    {
                        name: "第三十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第三十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Ss",
                pages: [
                    {
                        name: "第四十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第四十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Tt",
                pages: [
                    {
                        name: "第四十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第四十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Uu",
                pages: [
                    {
                        name: "第四十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第四十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Vv",
                pages: [
                    {
                        name: "第四十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第四十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Ww",
                pages: [
                    {
                        name: "第四十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第四十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Xx",
                pages: [
                    {
                        name: "第五十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第五十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Yy",
                pages: [
                    {
                        name: "第五十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第五十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
            {
                unit: "Zz",
                pages: [
                    {
                        name: "第五十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                    {
                        name: "第五十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%B8%80%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"
                    },
                ]
            },
        ]
    },
    {
        name: "元音组合发音训练Ⅰ",
        imgLink: require("../assets/book/book2.jpg"),
        index: 2,
        units: [
            {
                unit: "Unit 1",
                pages: [
                    {
                        name: "第四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                ]
            },
            {
                unit: "Unit 2",
                pages: [
                    {
                        name: "第十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第二十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第二十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                ]
            },
            {
                unit: "Unit 3",
                pages: [
                    {
                        name: "第二十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第二十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第二十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第二十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第二十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第二十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第二十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                ]
            },
            {
                unit: "Unit 4",
                pages: [
                    {
                        name: "第二十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                ]
            },
            {
                unit: "Unit 5",
                pages: [

                    {
                        name: "第三十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第三十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                ]
            },
            {
                unit: "Unit 6",
                pages: [
                    {
                        name: "第四十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%9B%9B%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%94%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AD%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%83%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AB%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第四十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B9%9D%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                ]
            },
            {
                unit: "Unit 7",
                pages: [
                    {
                        name: "第五十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第五十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%80%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第五十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%BA%8C%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                    {
                        name: "第五十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%8C%E5%86%8C/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%89%E9%A1%B5/%E5%AE%8C%E6%95%B4.mp3"

                    },
                ]
            },
        ]
    },
    {
        name: "元音组合发音训练Ⅱ",
        imgLink: require("../assets/book/book3.jpg"),
        index: 3,
        units: [
            {
                unit: "Unit 8",
                pages: [
                    {
                        name: "第四页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第五页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第六页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第七页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第八页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%85%AB%E9%A1%B5/%E7%AC%AC%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第九页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E9%A1%B5/%E7%AC%AC%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Unit 9",
                pages: [
                    {
                        name: "第十一页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十二页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十三页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十四页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十五页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十六页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十七页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Unit 10",
                pages: [
                    {
                        name: "第十八页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十九页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十一页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十二页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Unit 11",
                pages: [
                    {
                        name: "第二十三页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十四页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十五页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十六页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十七页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十八页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Unit 12",
                pages: [
                    {
                        name: "第二十九页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Unit 13",
                pages: [
                    {
                        name: "第三十一页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十二页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十三页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十四页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十五页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Unit 14",
                pages: [
                    {
                        name: "第三十六页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十七页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十八页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十九页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Unit 15",
                pages: [
                    {
                        name: "第四十一页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十二页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%83%AD%E5%8C%BA5%20%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十三页",
                        audioLink: "http://g.dgyonglian.com/%E8%87%AA%E7%84%B6%E6%8B%BC%E8%AF%BB/%E7%AC%AC%E4%B8%89%E5%86%8C/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
        ]
    },
    {
        name: "辅音组合发音训练",
        imgLink: require("../assets/book/book4.jpg"),
        index: 4,
        units: [
            {
                unit: "Part1 Unit 1",
                pages: [
                    {
                        name: "第四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/01%20%E7%AC%AC%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/02%20%E7%AC%AC%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/03%20%E7%AC%AC%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/04%20%E7%AC%AC%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/05%20%E7%AC%AC%E5%85%AB%E9%A1%B5/%E7%AC%AC%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/06%20%E7%AC%AC%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/07%20%E7%AC%AC%E5%8D%81%E9%A1%B5/%E7%AC%AC%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part1 Unit 2",
                pages: [{
                    name: "第十一页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/08%20%E7%AC%AC%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/09%20%E7%AC%AC%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/10%20%E7%AC%AC%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/11%20%E7%AC%AC%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/12%20%E7%AC%AC%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/13%20%E7%AC%AC%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/14%20%E7%AC%AC%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%912.mp3"
                    },
                    {
                        name: "第十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/15%20%E7%AC%AC%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/16%20%E7%AC%AC%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part1 Unit 3",
                pages: [{
                    name: "第二十页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/17%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第二十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/18%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/19%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/20%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/21%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/22%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/23%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part2 Unit 1",
                pages: [{
                    name: "第二十七页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/24%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第二十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/25%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/26%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/27%20%E7%AC%AC%E4%B8%89%E5%8D%81%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part2 Unit 2",
                pages: [{
                    name: "第三十一页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/28%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第三十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/29%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/30%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part2 Unit 3",
                pages: [{
                    name: "第三十四页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/31%20%E7%AC%AC%E4%B8%89%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第三十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/32%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/33%20%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part3 Unit 1",
                pages: [{
                    name: "第三十七页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/34%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第三十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/35%20%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/36%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part3 Unit 2",
                pages: [{
                    name: "第四十页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/37%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第四十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/38%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/39%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/40%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },]
            },
            {
                unit: "Part3 Unit 3",
                pages: [{
                    name: "第四十四页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/41%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第四十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/42%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/43%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/44%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part3 Unit 4",
                pages: [{
                    name: "第四十八页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/45%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第四十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/46%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第五十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/47%20%E7%AC%AC%E4%BA%94%E5%8D%81%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part4 Unit 1",
                pages: [{
                    name: "第五十一页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/48%20%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第五十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/49%20%E7%AC%AC%E4%BA%94%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第五十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/50%20%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part4 Unit 2",
                pages: [{
                    name: "第五十四页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/51%20%E7%AC%AC%E4%BA%94%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%83%AD%E5%8C%BA17%20The%20clown%20is%20in%20town.The%20clown%20has%20a%20crown.mp3"
                },
                    {
                        name: "第五十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/52%20%E7%AC%AC%E4%BA%94%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第五十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/53%20%E7%AC%AC%E4%BA%94%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part4 Unit 3",
                pages: [{
                    name: "第五十七页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/54%20%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第五十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/55%20%E7%AC%AC%E4%BA%94%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第五十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/56%20%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%BA%94%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part5 Unit 1",
                pages: [{
                    name: "第六十页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/57%20%E7%AC%AC%E5%85%AD%E5%8D%81%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第六十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/58%20%E7%AC%AC%E5%85%AD%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第六十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/59%20%E7%AC%AC%E5%85%AD%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第六十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/60%20%E7%AC%AC%E5%85%AD%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },]
            },
            {
                unit: "Part5 Unit 2",
                pages: [{
                    name: "第六十四页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/61%20%E7%AC%AC%E5%85%AD%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第六十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/62%20%E7%AC%AC%E5%85%AD%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第六十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/63%20%E7%AC%AC%E5%85%AD%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part5 Unit 3",
                pages: [{
                    name: "第六十七页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/64%20%E7%AC%AC%E5%85%AD%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第六十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/65%20%E7%AC%AC%E5%85%AD%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第六十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/66%20%E7%AC%AC%E5%85%AD%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E5%85%AD%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }]
            },
            {
                unit: "Part5 Unit 4",
                pages: [{
                    name: "第七十页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/67%20%E7%AC%AC%E4%B8%83%E5%8D%81%E9%A1%B5/%E7%AC%AC%E4%B8%83%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第七十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/68%20%E7%AC%AC%E4%B8%83%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E4%B8%83%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第七十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/69%20%E7%AC%AC%E4%B8%83%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E4%B8%83%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第七十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/70%20%E7%AC%AC%E4%B8%83%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E4%B8%83%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },]
            },
            {
                unit: "Part5 Unit 5",
                pages: [{
                    name: "第七十四页",
                    audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/71%20%E7%AC%AC%E4%B8%83%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E4%B8%83%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                },
                    {
                        name: "第七十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/72%20%E7%AC%AC%E4%B8%83%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%B8%83%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第七十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E5%9B%9B%E5%86%8C/73%20%E7%AC%AC%E4%B8%83%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E4%B8%83%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },]
            }
        ]
    },
    {
        name: "故事短句阅读训练",
        imgLink: require("../assets/book/book5.jpg"),
        index: 5,
        units: [
            {
                unit: "Part1 Review 1",
                pages: [
                    {
                        name: "第四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/01%20%E7%AC%AC%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part1 Review 2",
                pages: [
                    {
                        name: "第五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/02%20%E7%AC%AC%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/03%20%E7%AC%AC%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    }
                ]
            },
            {
                unit: "Part1 Review 3",
                pages: [
                    {
                        name: "第七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/04%20%E7%AC%AC%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/05%20%E7%AC%AC%E5%85%AB%E9%A1%B5/%E7%AC%AC%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/06%20%E7%AC%AC%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/07%20%E7%AC%AC%E5%8D%81%E9%A1%B5/%E7%AC%AC%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part1 Review 4",
                pages: [
                    {
                        name: "第十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/08%20%E7%AC%AC%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part1 Review 5",
                pages: [
                    {
                        name: "第十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/09%20%E7%AC%AC%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part1 Review 6",
                pages: [
                    {
                        name: "第十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/10%20%E7%AC%AC%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part2 Unit 1",
                pages: [
                    {
                        name: "第十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/11%20%E7%AC%AC%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/12%20%E7%AC%AC%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part2 Unit 2",
                pages: [

                    {
                        name: "第十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/13%20%E7%AC%AC%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/14%20%E7%AC%AC%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/15%20%E7%AC%AC%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/16%20%E7%AC%AC%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part2 Unit 3",
                pages: [
                    {
                        name: "第二十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/17%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/18%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/19%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/20%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/21%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/22%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/23%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/24%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part3",
                pages: [
                    {
                        name: "第二十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/25%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第二十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/26%20%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/27%20%E7%AC%AC%E4%B8%89%E5%8D%81%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/28%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            },
            {
                unit: "Part4",
                pages: [
                    {
                        name: "第三十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/29%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/30%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/31%20%E7%AC%AC%E4%B8%89%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/32%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/33%20%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/34%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十八页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/35%20%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AB%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E5%85%AB%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第三十九页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/36%20%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B9%9D%E9%A1%B5/%E7%AC%AC%E4%B8%89%E5%8D%81%E4%B9%9D%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/37%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十一页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/38%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%80%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%80%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十二页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/39%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%8C%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%8C%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十三页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/40%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%89%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%89%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十四页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/41%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%9B%9B%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%9B%9B%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十五页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/42%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%94%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%BA%94%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十六页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/43%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AD%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E5%85%AD%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                    {
                        name: "第四十七页",
                        audioLink: "http://g.dgyonglian.com/%E7%AC%AC%E4%BA%94%E5%86%8C/44%20%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%83%E9%A1%B5/%E7%AC%AC%E5%9B%9B%E5%8D%81%E4%B8%83%E9%A1%B5%E5%AE%8C%E6%95%B4%E9%9F%B3%E9%A2%91.mp3"
                    },
                ]
            }

        ]
    }
]
